import './assets/sass/app.scss';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import heroHomeImg from './assets/images/banner-1.jpeg';
import Advantages from './components/Advantages';

function App() {
  return (
    <>
      <Header />
        <div className="main-content">
          <section
            className='section-content hero'
            style={{ backgroundImage: `url(${heroHomeImg})` }}
          >
            <div className='content-inner'>
              <div className='hero-content'>
                <div className='hero-content-info'>
                  <h1>Ahora con Flypass y Bancolombia, viajas sin detenerte</h1>
                </div>
                <div className='hero-content-form'></div>
              </div>
            </div>
          </section>
          <Advantages />
        </div>
      <Footer />
    </>
  );
}

export default App;
