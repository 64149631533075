import logoFlypass from '../assets/images/logoFlypass_Bancolombia_normal_fondoColor.svg';

const Advantages = () => {

  const registerLink = () => {
    window.open('https://clientes.flypass.com.co/#!/register/person', '_blank');
  }

  const flypassLink = e => {
    e.preventDefault();
    window.open('https://flypass.com.co/personas/novedades/bancolombia-y-flypass-se-unen-para-darte-dos-regalos-de-navidad', '_blank');
  }

  return (
    <section className="section-content advantages">
      <svg className="shapeCurveUp scu-before" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 100 C 20 0 50 0 100 100 Z"></path>
      </svg>
      <svg className="shapeCurveUp" xmlns="http://www.w3.org/2000/svg" version="1.1" width="100%" height="100" viewBox="0 0 100 100" preserveAspectRatio="none">
        <path d="M0 100 C 20 0 50 0 100 100 Z"></path>
      </svg>
      <div className="content-inner">
        <img className="logoFlypass" src={logoFlypass} alt="logo Flypass" />
        {/* <p style={{ fontSize: 20, margin: 0, color: '#888' }}>
          Ya tienes tu tag, ahora solo debes:
        </p> */}
        {/* <ul>
          <li>
            <i className="icon icon-registro"></i>
            <h4>1. Registrarte en <a href="#" onClick={flypassLink} style={{ marginTop: 40, display: 'inline-block', fontWeight: 700 }} id="lp-conoce-mas-2">flypass.com.co</a></h4>
          </li>
          <li>
            <i className="icon icon-agregar-tarjeta"></i>
            <h4>2. Registrar tu medio de pago Bancolombia. ¡Y listo!</h4>
          </li>
        </ul> */}
        <p style={{ fontSize: 20, marginTop: 20, marginBottom: 40, color: '#888' }}>
          Bienvenido a esta gran familia, de todos los que disfrutamos más de nuestros viajes sin preocuparnos por el efectivo.
        </p>
        <p style={{ fontSize: 20, marginTop: 20, marginBottom: 40, color: '#888' }}>
          Recuerda que con nuestra alianza Flypass y Bancolombia vinculas un medio de pago (Tarjeta crédito, débito o corriente) y listo, sin filas, sin demoras y lo mejor, sin preocuparte por recargar; solo eliges entre estas dos formas de vinculación:
        </p>
        <p style={{ fontSize: 20, marginTop: 20, marginBottom: 40, color: '#888' }}>
          Pasa y Paga: solo tienes que inscribir tu medio de pago Bancolombia, en tu cuenta Flypass y cada vez que uses alguno de nuestros servicios, el valor de éste será debitado del producto que elegiste.
        </p>
        <p style={{ fontSize: 20, marginTop: 20, marginBottom: 40, color: '#888' }}>
          Recarga automática: al inscribir tu medio de pago Bancolombia defines un monto mínimo de dinero al que tu cuenta Flypass puede llegar, cuando uses alguno de nuestros servicios, el valor de éste será descontado de lo que hayas recargado y cuando alcance ese monto mínimo que definiste, Flypass solicitará la recarga automática al medio de pago que inscribiste anteriormente.
        </p>
        <p style={{ fontSize: 20, marginTop: 20, marginBottom: 40, color: '#888' }}>
          ¡Que esperas!, compra tu Tag, actívalo, elige la forma que más se ajusta a tus necesidades, vincula tu medio de pago y a disfrutar sin preocupaciones.
        </p>
        <button onClick={registerLink} className="flyp-button" id="lp-registro">Compra tu Tag</button>
        <br />
        <a href="#" onClick={flypassLink} style={{ marginTop: 40, display: 'inline-block', fontWeight: 700 }} id="lp-conoce-mas">
          Conoce más
        </a>
      </div>
    </section>
  );
};

export default Advantages;
