import logoFlypass from '../../assets/images/logoFlypass_Bancolombia_normal_fondoColor.svg';

const Header = () => {
  return (
    <header className="header">
      <div className="header-inner">
        <a className="logo" href="https://flypass.com.co" target="_blank" rel="noopener noreferrer">
          <img src={logoFlypass} alt="Logo Flypass Alianza Bancolombia" />
        </a>
      </div>
    </header>
  );
};

export default Header;
